export default {
  init() {
    $('#cvform').on('submit', function (event) {
      event.preventDefault();
      const $form = $(this);
      const formData = new FormData($form[0]);
      $('.cvform__message--ok, .cvform__empty, .cvform__message--error').hide();
      $.ajax({
        type: 'POST',
        url: $form.attr('action'),
        processData: false,
        contentType: false,
        data: formData,
        dataType: 'json',
        success: function (response) {
          // console.log(response);
          switch (response.response) {
            case 'ok':
              $('.cvform__message--ok').show();
              $('#cvform').hide();
              break;

            case 'empty':
              $('.cvform__empty').show();
              break;

            case 'error':
              $('.cvform__message--error').show();
              $('#cvform').hide();
              break;
          }
        }
      }); // return false;
    });
    $('.dropdown-item').on('click', event => {
      const $item = $(event.currentTarget);
      $item.closest('.dropdown-menu').prev().find('.dropdown-toggle__text').text($item.text());

      if ($item[0].hasAttribute('data-enterprise')) {
        $('#cv82-enterprise').val($item.data('enterprise'));
      }
    });
    $('#cv82-file').on('change', function () {
      // console.log($(this));
      const filename = $(this).val().toString().replace(/^.*\\/, '');
      $(this).next('.custom-file-label').find('.filename-text').text(filename);
    });
    const button = $('#cvform .btn-submit'); // console.log($('#cvform .custom-control-input').is(':checked'));

    if ($('#cvform .custom-control-input').is(':checked')) {
      button.addClass('btn-primary');
      button.removeClass('btn-outline-primary');
      button.prop('disabled', false);
    } else {
      button.addClass('btn-outline-primary');
      button.removeClass('btn-primary');
      button.prop('disabled', true);
    }

    $('#cvform').on('change', '.custom-control-input', function (event) {
      if (event.currentTarget.checked) {
        button.addClass('btn-primary');
        button.removeClass('btn-outline-primary');
      } else {
        button.addClass('btn-outline-primary');
        button.removeClass('btn-primary');
      }

      button.prop('disabled', !event.currentTarget.checked);
    });
  },

  finalize() {// JavaScript to be fired on all pages, after page specific JS is fired
  }

};