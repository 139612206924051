export default {
  init() {
    // JavaScript to be fired on all pages
    let loadingNews = false;

    function processLoadingNews($button) {
      $button.closest('.news__pagination').find('.news__loading').addClass('active');
      const currentPage = parseInt($button.data('currentpage'));
      let pageSize = parseInt($button.data('pagesize'));
      const firstItems = parseInt($button.data('firstitems'));
      const urlQuery = $button.data('urlquery');

      if (isNaN(pageSize)) {
        pageSize = 3;
      }

      if (!isNaN(currentPage)) {
        const offset = firstItems; // if (currentPage === 0) {
        //   offset = firstItems;
        // }
        // console.log(currentPage + 1, pageSize, offset);

        setTimeout(() => {
          $.ajax({
            url: urlQuery,
            type: 'get',
            data: {
              page: currentPage + 1,
              pagesize: pageSize,
              offset: offset
            }
          }).done(function (html) {
            const news = $(html).find('#news .news');
            $('#news .news__list').append(news);
            const pagination = $(html).find('#news .news__pagination');
            $('#news .news__pagination').empty();

            if (pagination.length > 0) {
              $('#news .news__pagination').html(pagination);
            }
          }).always(function () {
            $button.closest('.news__pagination').find('.news__loading').removeClass('active');
            loadingNews = false;
          });
        }, 500);
      }
    }

    $('#news').on('click', '.news__loadmore', function (event) {
      if (!loadingNews) {
        // console.log(event);
        loadingNews = true;
        const $button = $(event.currentTarget);
        processLoadingNews($button);
      }
    });
    $(window).scroll(function () {
      if (!loadingNews) {
        const fromTop = $(window).scrollTop() + $(window).height();
        const fromBottom = $(document).height() - $('.footer').outerHeight() / 2; // const fromBottom = $(document).height() - 50;
        // console.log('fromTop', fromTop);
        // console.log('fromBottom', fromBottom);

        if (fromTop > fromBottom) {
          loadingNews = true;
          const $button = $('.news__loadmore');
          processLoadingNews($button);
        }
      }
    }); // --
    // Carga de noticias en ventana

    let scrollTop = 0;
    let hrefActual = window.location.href;

    function closeSingleNews() {
      $('#singleNew').addClass('hide-list-news');
      setTimeout(() => {
        $('#singleNew').remove();
        $('#listNews').show();
        $(window).scrollTop(scrollTop);
      }, 400);
      $('#listNews').removeClass('hide-list-news');
      window.history.pushState({
        action: 'closeNew'
      }, '', hrefActual);
    } // Llamada Ajax para carga de Html de Noticia


    function loadAjaxNews(urlNews) {
      $.ajax({
        url: urlNews
      }).done(function (data) {
        const newsContent = $(data).find('#singleNew'); // console.log(newsContent);

        if (newsContent.length > 0) {
          scrollTop = $(window).scrollTop(); // console.log(scrollTop);

          window.history.pushState({
            action: 'openNew'
          }, '', urlNews);
          newsContent.addClass('hide-list-news');
          $('#listNews').addClass('hide-list-news');
          setTimeout(() => {
            $(window).scrollTop(0);
            $('#listNews').before(newsContent);
            $('#listNews').hide();
            newsContent.removeClass('hide-list-news');
          }, 400); // Activar los botones de cierre

          newsContent.on('click', '.close-newsitem', function (event) {
            event.preventDefault();
            closeSingleNews();
          });
        }
      });
    }

    $('.news-page .news__list').on('click', '.news__title__link', function (event) {
      const hrefNewsItem = event.currentTarget.getAttribute('href');
      console.log('Link:', hrefNewsItem);

      if (hrefNewsItem) {
        event.preventDefault();
        loadAjaxNews(hrefNewsItem);
      }
    });

    window.onpopstate = function (event) {
      if (event.state.action) {
        console.log('location: ' + document.location + ', state: ' + JSON.stringify(event.state));

        if (event.state.action === 'openNew') {
          loadAjaxNews(document.location.href);
        } else {
          closeSingleNews();
        }
      }
    };
  },

  finalize() {// JavaScript to be fired on all pages, after page specific JS is fired
  }

};