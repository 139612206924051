export default {
  init() {
    // JavaScript to be fired on the home page
    // Cargamos las noticias
    const $layerNews = $('#getnews');

    if ($layerNews.length > 0) {
      // '/noticias/?page=1&pagesize=3'
      const urlQuery = $layerNews.data('url'); // const currentPage = $layerNews.data('page');

      const pageSize = $layerNews.data('pagesize');
      $.ajax({
        url: urlQuery,
        type: 'get',
        data: {
          // page: currentPage,
          pagesize: pageSize,
          // offset: 0,
          featured: 1
        }
      }).done(function (html) {
        const news = $(html).find('#news .news');
        $layerNews.find('.news-replace').replaceWith(news);
        $('.more-news').removeClass('d-none');
      }).always(function () {// $button.closest('.news__pagination').find('.news__loading').removeClass('active');
      });
    }
  },

  finalize() {// JavaScript to be fired on the home page, after the init JS
  }

};