// import slick from 'slick-carousel/slick/slick';
import $ from 'jquery';
import 'slick-carousel';
export default {
  init() {
    // JavaScript to be fired on all pages
    $('.chef__list--use-slider').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: '<button type="button" class="slick-next"><i class="fal fa-chevron-right"></i> Siguiente</button>',
      prevArrow: '<button type="button" class="slick-prev"><i class="fal fa-chevron-left"></i> Anterior</button>',
      responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }, {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 576,
        settings: 'unslick'
      }]
    });
  },

  finalize() {// JavaScript to be fired on all pages, after page specific JS is fired
  }

};