// import external dependencies
import 'jquery'; // Import everything from autoload

import './autoload/_bootstrap.js'; // Cookies
// import * as Cookies from 'js-cookie';
// Import Slick
// import 'slick-carousel';

import 'slick-carousel/slick/slick'; // import then needed Font Awesome functionality

import { library, dom } from '@fortawesome/fontawesome-svg-core'; // import the Facebook and Twitter icons

import { faFacebookF, faTwitter, faInstagram, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faFileAudio, faFileVideo, faFileImage, faFileAlt, faChevronUp, faChevronDown, faChevronRight, faChevronLeft, faFilePdf, faTimes, faArrowRight, faLink } from '@fortawesome/pro-light-svg-icons';
import { faExternalLink, faArrowDown, faArrowLeft, faChevronUp as faChevronUpRegular, faChevronDown as faChevronDownRegular, faCheck, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { faPlayCircle, faShoppingCart, faMapMarkerAlt, faUpload } from '@fortawesome/pro-solid-svg-icons'; // https://github.com/piersrueb/dot-nav/
// import local dependencies

import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import newsPage from './routes/news-page';
import marronGlace from './routes/marron-glace';
import locatorPage from './routes/locator-page';
import curriculumPage from './routes/curriculum-page';
/** Populate Router instance with DOM routes */

const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  newsPage,
  marronGlace,
  locatorPage,
  curriculumPage
}); // Load Events

jQuery(document).ready(() => routes.loadEvents()); // FontAwesome
// add the imported icons to the library

library.add(faFacebookF, faTwitter, faInstagram, faLinkedinIn, faYoutube, faFileAudio, faFileVideo, faFileImage, faFileAlt, faExternalLink, faPlayCircle, faChevronUp, faChevronDown, faShoppingCart, faArrowDown, faFilePdf, faArrowLeft, faArrowRight, faTimes, faChevronRight, faChevronLeft, faMapMarkerAlt, faChevronUpRegular, faChevronDownRegular, faUpload, faLink, faCheck, faExclamationTriangle); // tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup

dom.watch();