// Import AOS
import AOS from 'aos/dist/aos'; // SimpleParallax (https://github.com/geosigno/simpleParallax.js/)

import simpleParallax from 'simple-parallax-js'; // Cookies

import Cookies from 'js-cookie'; // Scroll dots

import { dotNav } from '../util/dotNav';
import BigPicture from 'bigpicture';
export default {
  init() {
    // JavaScript to be fired on all pages
    // AOS animations
    AOS.init(); // function disableScroll() {
    //   // Get the current page scroll position
    //   const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    //   const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    //   // if any scroll is attempted, set this to the previous value
    //   window.onscroll = function() {
    //     window.scrollTo(scrollLeft, scrollTop);
    //   };
    // }
    // function enableScroll() {
    //   window.onscroll = function() {};
    // }
    // JavaScript to be fired on all pages

    $('.hamburger').click(function () {
      // const isActive = $(this).hasClass('is-active');
      $(this).toggleClass('is-active');
      $('#navigation').toggleClass('navigation--closed');
      $('body').toggleClass('navigation--opened'); // Bloqueamos el scroll --> TODO: No funciona
      // if (isActive) {
      //   enableScroll();
      // } else {
      //   disableScroll();
      // }
    });

    function setScrollPast() {
      const scroll = $(window).scrollTop();
      let scrollHeightMax = $('body').data('startscrolly');

      if (!scrollHeightMax) {
        scrollHeightMax = $(window).height();
      }

      if (scroll > scrollHeightMax) {
        $('body').addClass('scroll--past');
      } else {
        $('body').removeClass('scroll--past');
      }
    }

    $(window).scroll(function () {
      setScrollPast();
    });
    setScrollPast(); // Parallax

    const imagesParallax = $('img[data-parallax="true"]');
    imagesParallax.each((index, image) => {
      const options = {
        orientation: $(image).data('parallax-orientation') || 'up',
        scale: $(image).data('parallax-scale') || 1.2,
        delay: $(image).data('parallax-delay') || 0.4
      }; // console.log(options);

      new simpleParallax(image, options);
    }); // --
    // Carga de navegación por scroll

    const dotNavigation = $('#dot-nav');

    if (dotNavigation.length > 0) {
      dotNav('section', 'easeInOutCubic');
    }

    $('#start-scroll').on('click', event => {
      const dot = $('#dot-nav a:nth-child(2)');

      if (dot.length > 0) {
        dot.get(0).click();
      }

      $(event.currentTarget).fadeOut(1000); // setTimeout(() => {
      //   // console.log(event.currentTarget);
      //   $(event.currentTarget).removeClass('animate__fadeInDown');
      //   $(event.currentTarget).addClass('animate__fadeOut');
      //   $(event.currentTarget).removeClass('animate__infinite');
      //   $(event.currentTarget).removeClass('animate__delay-5s');
      // }, 300);
    }); // --
    // Enlaces con preload de páginas

    $('.navigation, .brand-logo, .fl-logos__item__link').on('click', 'a', function (event) {
      const $link = $(this);

      if (!$link[0].hasAttribute('data-toggle')) {
        event.preventDefault();
        event.stopPropagation();
        const travelTo = $(this).attr('href');

        if (travelTo !== '#') {
          $('body').addClass('loading');
          setTimeout(function () {
            window.location.href = travelTo;
          }, 1200);
        }
      }
    }); // --
    // Footer navigation

    $('.nav-link-submenu').on('click', '.nav-link--has-submenu', function (event) {
      const $link = $(event.currentTarget); // console.log($link);

      const $submenu = $link.next('.submenu');

      if ($submenu.hasClass('open')) {
        $link.find('.fa-chevron-up').hide();
        $link.find('.fa-chevron-down').show();
      } else {
        $link.find('.fa-chevron-up').show();
        $link.find('.fa-chevron-down').hide();
      }

      $submenu.toggleClass('open');
    });
    $(document).mouseup(function (e) {
      const container = $('.nav-link-submenu');

      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $('.submenu').removeClass('open');
      }
    }); // --
    // Apertura de modales con imágenes y videos

    function openBigPictureImage(element, url) {
      BigPicture({
        el: element,
        imgSrc: url
      });
    }

    function openBigPictureVideo(element, url) {
      BigPicture({
        el: element,
        vidSrc: url
      });
    }

    function openBigPictureYoutube(element, url, dimensions) {
      console.log('Youtube', dimensions);
      const options = {
        el: element,
        ytSrc: url
      };

      if (dimensions) {
        options.dimensions = dimensions;
      }

      BigPicture(options);
    }

    function openBigPictureVimeo(element, url, dimensions) {
      const options = {
        el: element,
        vimeoSrc: url
      };

      if (dimensions) {
        options.dimensions = dimensions;
      }

      BigPicture(options);
    }

    $('a[data-ytvideo]').on('click', event => {
      event.preventDefault();
      event.stopImmediatePropagation();
      const $element = $(event.currentTarget);
      const ytVideo = $element.data('ytvideo');
      let dimensions = null;

      if (event.currentTarget.hasAttribute('data-dimensions')) {
        dimensions = $element.data('dimensions');
      }

      openBigPictureYoutube(event.currentTarget, ytVideo, dimensions);
    });
    $('a[data-vimeovideo]').on('click', event => {
      event.preventDefault();
      const $element = $(event.currentTarget);
      const vimeoVideo = $element.data('vimeovideo');
      let dimensions = null;

      if (event.currentTarget.hasAttribute('data-dimensions')) {
        dimensions = $element.data('dimensions');
      }

      openBigPictureVimeo(event.currentTarget, vimeoVideo, dimensions);
    });
    $('a[data-video]').on('click', event => {
      event.preventDefault();
      event.stopImmediatePropagation();
      openBigPictureVideo(event.currentTarget, $(event.currentTarget).data('video'));
    });
    $('a[data-image]').on('click', event => {
      event.preventDefault();
      event.stopImmediatePropagation();
      const $element = $(event.currentTarget);
      openBigPictureImage(event.currentTarget, $element.data('image'));
    }); // --
    // Eventos de listado de noticias

    $('#news').on('click', 'a[data-video]', event => {
      event.preventDefault();
      event.stopImmediatePropagation();
      const $element = $(event.currentTarget);
      openBigPictureVideo(event.currentTarget, $element.data('video'));
    });
    $('#news').on('click', 'a[data-ytvideo]', event => {
      event.preventDefault();
      event.stopImmediatePropagation();
      const $element = $(event.currentTarget);
      const ytVideo = $element.data('ytvideo');
      let dimensions = null;

      if (event.currentTarget.hasAttribute('data-dimensions')) {
        dimensions = $element.data('dimensions');
      }

      openBigPictureYoutube(event.currentTarget, ytVideo, dimensions);
    });
    $('#news').on('click', 'a[data-image]', event => {
      event.preventDefault();
      event.stopImmediatePropagation();
      const $element = $(event.currentTarget);
      openBigPictureImage(event.currentTarget, $element.data('image'));
    });
    $('#news').on('click', 'a[data-gallery]', event => {
      event.preventDefault();
      const $element = $(event.currentTarget); // console.log($element.data('gallery'));

      const galleryImages = $element.data('gallery').map(function (imageSrc) {
        return {
          src: imageSrc // caption: 'This image is from unsplash'

        };
      }); // console.log(galleryImages);

      BigPicture({
        el: event.target,
        gallery: galleryImages // // optionally specify a starting index
        // position: 2,

      });
    }); // --
    // Barra de cookies

    const $cookieBanner = $('#cookie-banner');

    if ($cookieBanner.length > 0) {
      let cookieAccepted = Cookies.get('grupocuevas-cookielaw-accepted'); // console.log(cookieAccepted);

      if (cookieAccepted && cookieAccepted === '1') {
        // console.log('Aceptado');
        $cookieBanner.remove();
      } else {
        // console.log('No aceptado');
        setTimeout(() => {
          $cookieBanner.addClass('active');
        }, 1000);
      }

      $cookieBanner.on('click', '.cookie-banner__buttons__confirm', function (event) {
        event.preventDefault();
        event.stopPropagation(); // Cookies.set('grupocuevas-cookielaw-accepted', 'true', { expires: 182, path: '/' });

        Cookies.set('grupocuevas-cookielaw-accepted', '1', {
          path: '/'
        });
        $cookieBanner.removeClass('active');
        setTimeout(() => {
          $cookieBanner.remove();
        }, 1000);
      });
    } // --
    // Cambio de idioma


    $('.lang-sel__item--selected').on('click', function (event) {
      event.preventDefault();
      const $listLanguages = $(event.currentTarget).closest('.lang-sel__links');

      if ($listLanguages.hasClass('lang-sel__links--open')) {
        $listLanguages.removeClass('lang-sel__links--open');
      } else {
        $listLanguages.addClass('lang-sel__links--open');
      }
    });
    $(document).mouseup(function (e) {
      const container = $('.lang-sel');

      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $('.lang-sel__links--open').removeClass('lang-sel__links--open');
      }
    }); // Hide scroll down after 20 seconds

    setTimeout(() => {
      $('#start-scroll').fadeOut(1000);
    }, 21000);
  },

  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    setTimeout(() => {
      $('.image-kenburns').addClass('intro');
    }, 500); // console.log('Cuando carga todo');

    $('body').removeClass('loading');
  }

};