export default {
  init() {
    // JavaScript to be fired on all pages
    let locations = [];
    let locationsToRender = [];
    const searchLocatorFilter = {
      type: null,
      province: null
    };
    $('.dropdown-item').on('click', event => {
      // console.log(event.currentTarget);
      const $item = $(event.currentTarget);
      $item.closest('.dropdown-menu').prev().find('.dropdown-toggle__text').text($item.text());

      if ($item[0].hasAttribute('data-type')) {
        searchLocatorFilter.type = $item.data('type');
      }

      if ($item[0].hasAttribute('data-province')) {
        searchLocatorFilter.province = $item.data('province');
      }

      console.log('Filter:', searchLocatorFilter);
      locationsToRender = filterLocations();
      console.log(locationsToRender);
      renderLocations();
    });

    function filterLocations() {
      // console.log(locations);
      return locations.filter(location => {
        if (searchLocatorFilter.type != null && searchLocatorFilter.province != null) {
          return (location.type === searchLocatorFilter.type || searchLocatorFilter.type === 0) && (location.province === searchLocatorFilter.province || searchLocatorFilter.province === 0);
        } else {
          if (searchLocatorFilter.type != null) {
            return location.type === searchLocatorFilter.type || searchLocatorFilter.type === 0;
          } else {
            if (searchLocatorFilter.province != null) {
              return location.province === searchLocatorFilter.province || searchLocatorFilter.province === 0;
            }
          }
        }
      });
    }

    function renderLocations() {
      const $locations = $('#locations');
      $locations.empty();

      if (locationsToRender.length > 0) {
        locationsToRender.forEach(location => {
          const $col = $('<div class="col-sm-6 col-lg-3 mb-4"></div>');
          const $card = $('<div class="contact__card"></div>');
          const $name = $('<p class="text-uppercase font-weight-bold mb-3"></p>').text(location.name);
          const $address = $('<p class="mb-2"></p>');
          $address.append(location.address.line1);
          $address.append(location.address.line2 ? '<br>' + location.address.line2 : '');
          $address.append(location.address.line3 ? '<br>' + location.address.line3 : '');
          const $phones = $('<p class="mb-4"></p>');
          $phones.append(location.phone ? location.phone : '');
          $phones.append(location.fax ? '<br>' + location.fax : '');
          const $links = $('<p></p>');

          if (location.email) {
            $links.append('<a href="mailto:' + location.email + '">' + location.email + '</a>');
          }

          if (location.maplink) {
            $links.append('<br>');
            $links.append('<a target="_blank" href="' + location.maplink + '">ver en mapa</a>');
          }

          $card.append($name);
          $card.append($address);
          $card.append($phones);
          $card.append($links);
          $col.append($card);
          $locations.append($col);
        });
      } else {
        $locations.append('<div class="col-12"><p>No se han encontrado supermercados por estos criterios.</p></div>');
      }
    }

    const urlLocations = $('.locator-selector').data('locations-url');
    $.getJSON(urlLocations, function (data) {
      locations = data;
      buildSelector();
    });

    function buildSelector() {
      console.log('Generamos los selectores.');
    }
  },

  finalize() {// JavaScript to be fired on all pages, after page specific JS is fired
  }

};